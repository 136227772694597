import { IconButton } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useState, useRef } from 'react';

// Our Components
import FilterPopover from 'components/Filter/FilterPoppover';

// Local Constants
const FILTER_OPTIONS = [
	{ value: 'filterByAll', label: 'Show all messages' },
	{ value: 'filterByUnread', label: 'Show unread messages' },
	{ value: 'filterByRead', label: 'Show read messages' },
	{ value: 'filterBySubject', label: 'Show messages by subject' },
	{ value: 'filterByGeneral', label: 'Show only general channel messages' },
	{
		value: 'filterByClientsOnly',
		label: 'Show only client-specific messages'
	}
];

function FilterButton({ filterBy, setFilterBy, onChange, iconColor }) {
	// Ref for the popover anchor
	const anchorEl = useRef(null);

	// Popover State
	const [popoverOpen, setPopoverOpen] = useState(false);

	return (
		<>
			<IconButton ref={anchorEl} onClick={() => setPopoverOpen(true)}>
				<FilterAltIcon sx={{ color: iconColor, marginLeft: 1 }} />
			</IconButton>

			<FilterPopover
				anchorEl={anchorEl}
				popoverOpen={popoverOpen}
				setPopoverOpen={setPopoverOpen}
				filterOptions={FILTER_OPTIONS}
				filterBy={filterBy}
				setFilterBy={setFilterBy}
				onChange={onChange}
			/>
		</>
	);
}

FilterButton.defaultProps = {
	onChange: null,
	iconColor: '#000000'
};

export default FilterButton;
