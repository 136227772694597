// eslint-disable-next-line import/prefer-default-export

// Advisor
export const ADVISOR_DATA = 'ADVISOR_DATA';
export const ADVISOR_LOAN_REQUESTS = 'ADVISOR_LOAN_REQUESTS';
export const FIREBASEUSERCREDENTIALS = 'FIREBASEUSERCREDENTIALS';
export const USER_DATA = 'USER_DATA';
export const USERPROFILEDATA = 'USERPROFILEDATA';
export const CLIENT_PROFILE_DATA = 'CLIENT_PROFILE_DATA';
export const VERIFIEDCLIENTINVITEDATA = 'VERIFIEDCLIENTINVITEDATA';
export const ADVISOR_PROFILE_PICTURE = 'ADVISOR_PROFILE_PICTURE';
export const ADVISOR_COMPANY_LOGO = 'ADVISOR_COMPANY_LOGO';

// Client Onboarding
export const CLIENT_ONBOARDING_LIABILITY_INFO =
	'CLIENT_ONBOARDING_LIABILITY_INFO';
export const CLIENT_ONBOARDING_CREDITSCORE = 'CLIENT_ONBOARDING_CREDITSCORE';
export const CLIENT_ONBOARDING_SSN = 'CLIENT_ONBOARDING_SSN';
export const CLIENT_ONBOARDING_FINANCIAL_GOALS =
	'CLIENT_ONBOARDING_FINANCIAL_GOALS';
export const CLIENT_LIABILITY_RESPONSE = 'CLIENT_LIABILITY_RESPONSE';
export const CLIENT_VETERAN_STATUS = 'CLIENT_VETERAN_STATUS';
export const CLIENT_ONBOARDING_PRIMARYHOME = 'CLIENT_ONBOARDING_PRIMARYHOME';
export const CLIENT_ONBOARDING_HOMELENGTH = 'CLIENT_ONBOARDING_HOMELENGTH';
export const CLIENT_ONBOARDING_STATUS = 'CLIENT_ONBOARDING_STATUS';
export const CLIENT_SORA_WALLET = 'CLIENT_SORA_WALLET';

export const CLIENT_ONBOARDING_INCOME = 'CLIENT_ONBOARDING_INCOME';
export const CLIENT_ONBOARDING_ASSETS = 'CLIENT_ONBOARDING_ASSETS';

export const CLIENT_DATA = 'CLIENT_DATA';
export const CLIENT_REMOVED_DATA = 'CLIENT_REMOVED_DATA';

export const FILE_UPLOAD = 'FILE_UPLOAD';

export const LIABILITY = 'LIABILITY';

export const NEWSFEED_ITEMS_DATA = 'NEWSFEED_ITEMS_DATA';

// Clients
export const CLIENTS = 'CLIENTS';

// Advisors List
export const ADVISORS_LIST = 'ADVISORS_LIST';

// OFFERS
export const NEW_LOAN_OFFERS = 'NEW_LOAN_OFFERS';
export const REFINANCE_OFFERS = 'REFINANCE_OFFERS';
export const CASH_OUT_OFFERS = 'CASH_OUT_OFFERS';

// User
export const USER_LIABILITIES = 'USER_LIABILITIES';
export const USER_MORTGAGES = 'USER_MORTGAGES'; // can be serialized as [USER_MORTGAGES, tradeLineId]
export const USER_AUTOS = 'USER_AUTOS'; // can be serialized as [USER_AUTOS, tradeLineId]
export const USER_STUDENTS = 'USER_STUDENTS'; // can be serialized as [USER_STUDENTS, tradeLineId]
export const USER_PERSONALS = 'USER_PERSONALS'; // can be serialized as [USER_PERSONALS, tradeLineId]
export const USER_OTHER_LOANS = 'USER_OTHER_LOANS'; // can be serialized as [USER_OTHER_LOANS, tradeLineId]
export const EMPLOYEE_USER_DATA = 'EMPLOYEE_USER_DATA';
export const TEMP_CROPPED_COMPANY_LOGO_DATA = 'TEMP_CROPPED_COMPANY_LOGO_DATA';
export const USER_ALERT_SETTINGS = 'USER_ALERT_SETTINGS';

// Tradeline
export const TRADELINE_DATA = 'TRADELINE_DATA';

// Wallet
export const MAKE_ME_AN_OFFER_VALUE = 'MAKE_ME_AN_OFFER_VALUE';
export const WALLET_OVERVIEW_DATA = 'WALLET_OVERVIEW_DATA';
export const CLIENT_OVERVIEW_DATA = 'CLIENT_OVERVIEW_DATA';
export const GPT_REWRITTEN_INSIGHTS = 'GPT_REWRITTEN_INSIGHTS'; // Wallet Overview GPT insights
export const MORTGAGE_SPECIFIC_INSIGHTS = 'MORTGAGE_SPECIFIC_INSIGHTS'; // Mortgage Specific GPT insights
export const SORA_INSIGHTS = 'SORA_INSIGHTS'; // Wallet Overview Insights

// Liability Related
export const COMMERCIAL_LOAN_LIABILITY = 'COMMERCIAL_LOAN_LIABILITY';

// FA
export const FACLIENTS = 'FACLIENTS';
export const FALIABILITYSUMMARY = 'FALIABILITYSUMMARY';
export const FALOANSAPPLIED = 'FALOANSAPPLIED';
export const OFFERS = 'OFFERS';
export const FA_CLIENT_SORA_WALLET = 'FA_CLIENT_SORA_WALLET';
export const FA_CURRENT_CLIENT_ID = 'FA_CURRENT_CLIENT_ID'; // This can be removed when we revamp our clientId mechanism.
export const ROLE = 'ROLE';
export const FA_COMPANY_URL = 'FA_COMPANY_URL';
export const FA_COMPANY_ASSOCIATION = 'FA_COMPANY_ASSOCIATION';
export const CLIENTINVITECODE = 'CLIENTINVITECODE';
export const INVITEE_EMAIL = 'INVITEE_EMAIL';

// Sora
export const LIST_OF_ADVISORS = 'LIST_OF_ADVISORS';

// Methodfi
export const METHODFI_FOUND_LIABILITIES = 'METHODFI_FOUND_LIABILITIES';
export const METHODFI_ENTITY_ID = 'METHODFI_ENTITY_ID';
export const METHODFI_ACCOUNT_ID = 'METHOFI_ACCOUNT_ID';

// Pinwheel
export const PINWHEEL_INCOME_EMPLOYMENT_DATA =
	'PINWHEEL_INCOME_EMPLOYMENT_DATA';

export const EMPLOYMENT_DATA = 'EMPLOYMENT_DATA';

// Loan Requests
export const LOAN_REQUEST = 'LOAN_REQUEST';
export const LOAN_REQUEST_RELATED_DOCUMENTS = 'LOAN_REQUEST_RELATED_DOCUMENTS';
export const LOAN_REQUEST_DOCUMENT_TO_DOWNLOAD =
	'LOAN_REQUEST_DOCUMENT_TO_DOWNLOAD';

// Advisors
export const ADVISORS_ONBOARDED_COUNT = 'ADVISORS_ONBOARDED_COUNT';

// Freemium
export const FREEMIUM_ADVISORS = 'FREEMIUM_ADVISORS';
export const FREEMIUM_COMPANIES = 'FREEMIUM_COMPANIES';

// FA Onboarding
export const FA_ASSOCIATION_DATA = 'FA_ASSOCIATION_DATA';
export const UNVERIFIED_USER = 'UNVERIFIED_USER';

// APP State
export const ERROR_MESSAGE_DATA = 'ERROR_MESSAGE_DATA';
export const INFO_MESSAGE_DATA = 'INFO_MESSAGE_DATA';
export const SUCCESS_MESSAGE_DATA = 'SUCCESS_MESSAGE_DATA';

// Welcome Related
export const FROM_SIGNUP = 'FROM_SIGNUP'; // flag that enables the welcome modal on client additional details page.

// One Time Password
export const OTP_CONFIRM_RESULT = 'OTP_CONFIRM_RESULT';

// Analytics
export const ANALYTICS_OFFER_DATA = 'ANALYTICS_OFFER_DATA';
export const CLIENT_ANALYTICS_LIABILITY_DATA =
	'CLIENT_ANALYTICS_LIABILITY_DATA';

// Burn down chart
export const BURN_DOWN_CHART_DATA = 'BURN_DOWN_CHART_DATA';

// 3rd Party
export const TALKJS_CURRENT_USER_ID = 'TALKJS_CURRENT_USER_ID';
export const TALKJS_CURRENT_CONVERSATION_ID = 'TALKJS_CURRENT_CONVERSATION_ID';
export const TALKJS_ALL_UNREAD_MESSAGES = 'TALKJS_ALL_UNREAD_MESSAGES';
export const TALKJS_ALL_MESSAGES = 'TALKJS_ALL_MESSAGES';
export const WEALTH_BOX_CLIENTS = 'WEALTH_BOX_CLIENTS';
export const WEALTH_BOX_CLIENTS_CONNECT = 'WEALTH_BOX_CLIENTS_CONNECT';
