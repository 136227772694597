import { Box, Divider, Grid, Typography, Skeleton } from '@mui/material';
import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import Modal from 'components/Modal/Modal';

// Our Hooks
import useGetTradelineData from 'hooks/tradeline/useGetTradelineData';

// Our Utils
import { dollarFormatter, roundToDecimal } from 'shared/utils';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';

const HOME_PROPERTY_FIELDS = [
	'addressLine1',
	'addressLine2',
	'city',
	'state',
	'zipcode',
	'marketValue',
	'assedValueUsd',
	'estimatedValue',
	'confidenceScore',
	'propertyType',
	'builtYear',
	'toalSqft',
	'bedroomCount',
	'bathCount',
	'ownerName',
	'sellerName',
	'saleTxDate',
	'saleAmount',
	'taxValueUsd',
	'dwellingType'
];

const LIABILITY_INSIGHTS_FIELDS = ['untappedSaving', 'upsidePotential'];

const CURRENT_LIABILITIES_FIELDS = [
	'costOfDebt',
	'dataSource',
	'escrow',
	'lastRefreshedDate',
	'monthlyMortgagePayment',
	'monthlyPayment',
	'nextPaymentMinmumAmount',
	'originalMortgageBalance',
	'originalMortgageDate',
	'remainingMonth'
];

function getClientName(queryResult, clientData) {
	if (queryResult?.clientName) return queryResult?.clientName;

	if (clientData?.firstName && clientData?.lastName)
		return `${clientData?.firstName} ${clientData?.lastName}`;

	if (clientData?.firstName) return clientData?.firstName;

	return 'Unknown';
}

function formatString(input) {
	// Insert a space before all capital letters and numbers
	const withSpaces = input.replace(/([A-Z0-9])/g, ' $1');
	// Capitalize the first letter and trim any leading spaces
	return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1).trim();
}

function TradelineInfoBlock({
	tradelineData,
	title,
	uniqueFields,
	getRootField
}) {
	const { currentLoan } = tradelineData;

	const { tradeLineType, lender, interestRate, totalOutstandingBalance } =
		currentLoan;

	const loanOverViewSection = [
		{
			title: 'Loan Type',
			value: normalizeLoanType(tradeLineType) ?? 'Unknown'
		},
		{
			title: 'Lender',
			value: lender ?? 'Unknown'
		},
		{
			title: 'Balance Outstanding',
			value: dollarFormatter.format(totalOutstandingBalance)
		},
		{
			title: 'Interest Rate',
			value: roundToDecimal(interestRate) ?? 'Unknown'
		}
	];

	if (tradeLineType === 'MORTGAGE' && uniqueFields) {
		const mortgageFieldOfInterest = getRootField(tradelineData);

		if (!mortgageFieldOfInterest) {
			return null;
		}

		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
					marginBottom: 2
				}}
			>
				<Typography variant="subtitle1">{title}</Typography>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: 2,
						gap: 2,
						maxHeight: 425,
						overflowY: 'auto'
					}}
				>
					{uniqueFields.map((item) => {
						const fieldTitle = formatString(item);
						const fieldValue = mortgageFieldOfInterest[item];

						return (
							<Fragment key={uuidv4()}>
								<Box sx={{ display: 'flex', gap: 2 }}>
									<Typography variant="subtitle2">
										{fieldTitle}
									</Typography>
									<Typography variant="body1">
										{fieldValue ?? 'Unknown'}
									</Typography>
								</Box>
								<Divider />
							</Fragment>
						);
					})}
				</Box>
			</Box>
		);
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				marginBottom: 2,
				gap: 2
			}}
		>
			<Typography variant="subtitle1">{title}</Typography>
			{loanOverViewSection.map((item) => (
				<Fragment key={uuidv4()}>
					<Box sx={{ display: 'flex', gap: 2 }}>
						<Typography variant="subtitle2">
							{item.title}
						</Typography>
						<Typography variant="body1">{item.value}</Typography>
					</Box>
					<Divider />
				</Fragment>
			))}
		</Box>
	);
}

function TradelineInfoModal({ isOpen, handleClose, queryResult, clientData }) {
	const { tradelineId } = queryResult;

	const clientName = getClientName(queryResult, clientData);

	const { isLoading, data: tradelineData } = useGetTradelineData(
		null,
		tradelineId
	);

	const isMortgage = tradelineData?.currentLoan?.tradeLineType === 'MORTGAGE';

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			title={`Wallet Data for client: ${clientName}`}
		>
			{isLoading && (
				<Skeleton variant="rectangular" width="100%" height={300} />
			)}
			{!isLoading && tradelineData && (
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<TradelineInfoBlock
							tradelineData={tradelineData}
							title="Loan Overview Details"
						/>
					</Grid>
					{isMortgage && (
						<>
							<Grid item xs={12} md={6}>
								<TradelineInfoBlock
									tradelineData={tradelineData}
									title="Liability Insights"
									uniqueFields={LIABILITY_INSIGHTS_FIELDS}
									getRootField={(data) => data}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TradelineInfoBlock
									tradelineData={tradelineData}
									title="Property Data"
									uniqueFields={HOME_PROPERTY_FIELDS}
									getRootField={(data) =>
										data.currentLoan.home
									}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TradelineInfoBlock
									tradelineData={tradelineData}
									title="Current Liabilities Data"
									uniqueFields={CURRENT_LIABILITIES_FIELDS}
									getRootField={(data) => data.currentLoan}
								/>
							</Grid>
						</>
					)}
				</Grid>
			)}
		</Modal>
	);
}

export default TradelineInfoModal;
