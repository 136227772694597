import { useQuery } from 'react-query';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Our API Endpoints
import { faClients } from 'shared/api-urls';

// Our Query Keys
import { CLIENTS } from 'shared/query-keys';

// Our Constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useGetClients(enabled, AdvisorId) {
	return useQuery(
		[CLIENTS, AdvisorId ?? null],
		async () => {
			const res = await axiosInstance.get(`${faClients}/${AdvisorId}`);
			const advisorClients = res.data.data;

			const sortedClientsByName = advisorClients.sort((a, b) => {
				if (a.invitedName < b.invitedName) return -1;
				if (a.invitedName > b.invitedName) return 1;
				return 0;
			});

			const removeInvitedClients = sortedClientsByName.filter(
				({ clientId }) => clientId !== 0
			);

			return removeInvitedClients;
		},
		{
			enabled: enabled ?? false,
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES
		}
	);
}

export default useGetClients;
