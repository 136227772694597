import { Typography } from '@mui/material';
import { useMemo, useState, useCallback } from 'react';

// our components
import AllAdvisorsTable from 'components/Table/AllAdvisorsTable';
import DynamicTabs from 'components/Tabs/DynamicTabs';
import FreemiumTable from 'components/Table/FreemiumTable';
import FreemiumCompanyTable from 'components/Table/FreemiumCompanyTable';
import Loader from 'components/Loader';
import PageHeading from 'components/PageHeading/PageHeading';

// Our Hooks
import useGetAllAdvisorsList from 'hooks/advisors/useGetAllAdvisorsList';

const TABS_NAMES = [
	{ type: 'Freemium Advisors' },
	{ type: 'Freemium Companies' },
	{ type: 'View All Advisors' }
];

function AdvisorsPage() {
	// Tab State
	const [tabPanelSelected, setTabPanelSelected] = useState(0);

	const handleTabSelection = useCallback((e, value) => {
		setTabPanelSelected(value);
	}, []);

	const { isLoading, isSuccess, data } = useGetAllAdvisorsList((data) => {
		return data.length;
	});

	const tabPanelItems = useMemo(() => {
		return [
			{
				children: <FreemiumTable />
			},
			{
				children: <FreemiumCompanyTable />
			},
			{
				children: <AllAdvisorsTable />
			}
		];
	}, []);

	if (isLoading) return <Loader />;

	if (isSuccess) {
		return (
			<>
				<PageHeading
					title="Advisors"
					subtitleText={`${data} advisors onboarded`}
				/>

				<DynamicTabs
					tabItems={TABS_NAMES}
					tabItemSX={{
						padding: 0,
						textTransform: 'none',
						marginRight: 5
					}}
					tabsSX={{
						marginBottom: 4
					}}
					tabPanelItems={tabPanelItems}
					value={tabPanelSelected}
					handleChange={handleTabSelection}
					aria-label="Settings tabs"
				/>
			</>
		);
	}

	return <Typography variant="h1">No advisors onboarded</Typography>;
}

export default AdvisorsPage;
