import { Grid, Box, Typography, Skeleton } from '@mui/material';
import { useState } from 'react';

// Icons
import PercentIcon from '@mui/icons-material/Percent';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HomeIcon from '@mui/icons-material/Home';

// Our Components
import QueryEngineTable from 'components/DataTable/QueryEngineTable';
import RateInput from 'components/Input/RateInput';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';

// Our Hooks
import useMutateGenerateReport from 'hooks/genereateReport/useMutateGenerateReport';

// Our Utils
import { dollarFormatter, roundToDecimal } from 'shared/utils';

// Local Constants
const SIMPLE_QUERY_ITEMS = [
	{
		label: 'Interest Rate',
		icon: <PercentIcon />,
		description: 'Show all liabilities with rate above',
		fieldName: 'interestRate',
		tradelineType: 'ALL',
		formatter: roundToDecimal
	},
	{
		label: 'Auto',
		icon: <DirectionsCarIcon />,
		description: 'Show all auto liabilities above',
		fieldName: 'interestRate',
		tradelineType: 'AUTO',
		formatter: roundToDecimal
	},
	{
		label: 'Open Helocs',
		icon: <HomeIcon />,
		description: 'Show all open helocs above',
		fieldName: 'outstandingBalance',
		tradelineType: 'HELOC',
		formatter: dollarFormatter.format
	}
	// {
	// 	label: 'Credit Score',
	// 	icon: <PercentIcon />,
	// 	description: 'Show all clients with credit score below',
	// 	fieldName: 'creditScore',
	// 	tradelineType: 'ALL',
	// 	formatter: (value) => value
	// }
];

function QueryEngineSimple({ selectedClients, selectedAdvisor }) {
	// Mutations
	const generateReportApiCall = useMutateGenerateReport();

	const { isLoading: isQueryLoading, isSuccess: isQuerySuccess } =
		generateReportApiCall;

	const [currentInterestRate, setCurrentInterestRate] = useState('6.25');
	const [currentAutoRate, setCurrentAutoRate] = useState('6');
	const [currentOpenHelocRate, setCurrentOpenHelocRate] = useState('10000');
	// const [currentCreditScore, setCurrentCreditScore] = useState('700');

	// Query Results
	const [queryResults, setQueryResults] = useState([]);

	const handleGenerateReport = ({
		fieldName,
		currentStateValue,
		tradelineType
	}) => {
		const reportQuery = {
			fields: [
				{
					greaterThan: +currentStateValue,
					lessThan: Number.MAX_VALUE,
					fieldName
				}
			],
			tradelineType,
			advisorId: selectedAdvisor.id
		};

		// if the first item in clientId arr is an object with clientId of -1
		// it means the user selected ALL clients
		// and thus the BE spec is to omit `clients` from request payload
		const hasClients = selectedClients.length > 0;

		if (hasClients) {
			reportQuery.clients = selectedClients.map(
				({ clientId }) => clientId
			);
		}

		generateReportApiCall.mutate(reportQuery, {
			onSuccess: ({ data: queryData }) => setQueryResults(queryData.data)
		});
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
					Command center
				</Typography>
				<Typography variant="body1">
					Change values here to alter the results
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<RateInput
					value={currentInterestRate}
					setValue={setCurrentInterestRate}
					label="Interest Rate"
					subLabel="This changes all rates below for search"
					rate={currentInterestRate}
					setRate={setCurrentInterestRate}
				/>
			</Grid>
			<Grid item xs={4}>
				<RateInput
					value={currentAutoRate}
					setValue={setCurrentAutoRate}
					label="Auto Rate"
					subLabel="This changes all auto rates below for search"
					rate={currentAutoRate}
					setRate={setCurrentAutoRate}
				/>
			</Grid>
			<Grid item xs={4}>
				<CurrencyTextInput
					label="Open Helocs"
					subLabel="This changes all open helocs below for search"
					value={currentOpenHelocRate}
					onChange={setCurrentOpenHelocRate}
				/>
			</Grid>
			{/* <Grid item xs={4}>
				<TextInput
					label="Credit Score"
					subLabel="This changes all credit scores below for search"
					value={currentCreditScore}
					onChange={setCurrentCreditScore}
					type={TEXT}
				/>
			</Grid> */}

			<Grid item xs={12}>
				<Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
					Simple Query
				</Typography>
				<Typography variant="body1">
					Select a query from the list below to see the results
				</Typography>
			</Grid>

			{SIMPLE_QUERY_ITEMS.map(
				({
					label,
					icon,
					description,
					fieldName,
					tradelineType,
					formatter
				}) => {
					const stateToLabelMapping = {
						'Interest Rate': currentInterestRate,
						Auto: currentAutoRate,
						'Open Helocs': currentOpenHelocRate
						// 'Credit Score': currentCreditScore
					};

					const currentStateValue = stateToLabelMapping[label];
					const formattedCurrentStateValue =
						formatter(currentStateValue);

					const stateToDescriptionMapping = {
						'Interest Rate': `${description} ${formattedCurrentStateValue}`,
						Auto: `${description} ${formattedCurrentStateValue}`,
						'Open Helocs': `${description} ${formattedCurrentStateValue}`
						// 'Credit Score': `${description} ${formattedCurrentStateValue}`
					};

					return (
						<Grid item xs={12} sm={6} md={4} key={label}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									padding: 2,
									borderRadius: 4,
									gap: 4,
									border: '2px solid black',
									'&:hover': {
										cursor: 'pointer',
										backgroundColor: '#f0f0f0'
									}
								}}
								onClick={() =>
									handleGenerateReport({
										fieldName,
										currentStateValue,
										tradelineType
									})
								}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: 2
									}}
								>
									{icon}
									<Typography variant="h6">
										{label}
									</Typography>
								</Box>

								<Typography variant="body1">
									{stateToDescriptionMapping[label]}
								</Typography>
							</Box>
						</Grid>
					);
				}
			)}
			<Grid item xs={12}>
				{isQueryLoading && (
					<Skeleton variant="rectangular" width="100%" height={700} />
				)}
				{isQuerySuccess && (
					<QueryEngineTable
						queryResults={queryResults}
						isLoading={isQueryLoading}
					/>
				)}
			</Grid>
		</Grid>
	);
}

export default QueryEngineSimple;
