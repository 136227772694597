import { useState } from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Inbox } from '@talkjs/react';

// Our Components
import CreateChannelModal from 'components/Messaging/CreateChannelModal';
import FilterButton from 'components/Filter/FilterButton';
import FilterSubjectModal from 'components/Messaging/FilterSubjectModal';
import { TextButton } from 'components/Button/Button';

// Our Hooks
import useGetAllAdvisors from 'hooks/advisors/useGetAllAdvisors';

// TalkJsHooks
import useGetAllMessagesForCurrentUser from 'hooks/talkjs/queries/useGetAllMessagesForCurrentUser';

const INBOX_FEED_FILTER_OPTIONS_MAPPING = {
	filterByUnread: {
		hasUnreadMessages: true
	},
	filterByAll: {},
	filterByRead: {
		hasUnreadMessages: false
	},
	filterByGeneral: {
		subject: ['==', 'Sora General Channel']
	},
	filterByClientsOnly: {
		subject: ['!=', 'Sora General Channel']
	}
};

function AdminInboxTab() {
	// Advisor State
	const [currentConversation, setCurrentConversation] = useState(null);

	// Modal State
	const [isOpenModal, setIsOpenModal] = useState(false);

	// Filter Subject Modal State
	const [isOpenFilterSubjectModal, setIsOpenFilterSubjectModal] =
		useState(false);

	// Inbox State
	const [inboxFeedFilter, setInboxFeedFilter] = useState({});
	const [filterBy, setFilterBy] = useState('filterByAll');

	const resetInboxFeedFilter = () => {
		setInboxFeedFilter({});
		setFilterBy('filterByAll');
	};

	const handleInboxFeedFilterChange = (filterOption) => {
		if (filterOption === 'filterBySubject') {
			setFilterBy('filterBySubject');
			setIsOpenFilterSubjectModal(true);
			return;
		}
		// These are the options that are used for the feed filter
		setInboxFeedFilter(INBOX_FEED_FILTER_OPTIONS_MAPPING[filterOption]);
	};

	const { isLoading: isGetAdvisorsLoading, isSuccess: isGetAdvisorsSuccess } =
		useGetAllAdvisors();

	const {
		isLoading: isGetAllMessagesLoading,
		isSuccess: isGetAllMessaagesSuccess
	} = useGetAllMessagesForCurrentUser(setCurrentConversation);

	const isPageLoading = isGetAdvisorsLoading || isGetAllMessagesLoading;

	const isPageReady = isGetAdvisorsSuccess && isGetAllMessaagesSuccess;

	if (isPageLoading) {
		return (
			<Skeleton
				variant="rectangular"
				sx={{ height: 700, width: '56%' }}
			/>
		);
	}

	if (isPageReady) {
		return (
			<>
				<Box
					sx={{
						bgcolor: '#404040',
						width: '40%',
						maxWidth: 299.2,
						borderRadius: '0.75rem'
					}}
				>
					<FilterButton
						filterBy={filterBy}
						setFilterBy={setFilterBy}
						onChange={handleInboxFeedFilterChange}
						iconColor="#fff"
					/>

					<TextButton
						sx={{ marginLeft: 2.5, color: '#fff' }}
						onClick={() => setIsOpenModal(true)}
					>
						Start a new channel
					</TextButton>
				</Box>
				<Grid
					container
					item
					xs={12}
					xl={12}
					sx={{ justifyContent: 'flex-start' }}
				>
					<Inbox
						conversationId={currentConversation}
						style={{
							height: 700,
							width: '56%'
						}}
						messageField={{
							placeholder: 'Write a message...',
							visible: true
						}}
						loadingComponent={
							<Skeleton
								variant="rectangular"
								sx={{
									height: '100%',
									width: '100%'
								}}
							/>
						}
						feedFilter={inboxFeedFilter}
					/>
				</Grid>
				<CreateChannelModal
					isOpen={isOpenModal}
					handleClose={() => setIsOpenModal(false)}
					setCurrentConversation={setCurrentConversation}
				/>
				<FilterSubjectModal
					isOpen={isOpenFilterSubjectModal}
					handleClose={setIsOpenFilterSubjectModal}
					resetInboxFeedFilter={resetInboxFeedFilter}
					inboxFeedFilter={inboxFeedFilter}
					setFeedFilter={setInboxFeedFilter}
				/>
			</>
		);
	}

	return <Typography>Something went wrong.</Typography>;
}

export default AdminInboxTab;
