import { Grid, Box, Paper, Typography } from '@mui/material';
import { isValidElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

function ClientSectionCard({ clientData, boxSX, title, children }) {
	return (
		<Grid item xs={12} md={6}>
			<Box
				component={Paper}
				sx={{
					padding: 2,
					borderRadius: 4,
					marginBottom: 2,
					...boxSX
				}}
				elevation={3}
			>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<Typography
						variant="h3Gascogne"
						sx={{ display: 'block', marginBottom: 2 }}
					>
						{title}
					</Typography>
					{children}
				</Box>

				{clientData.map((info) => {
					const isNotValidElement = !isValidElement(info.value);

					if (isNotValidElement) {
						return (
							<Typography
								variant="body1"
								key={uuidv4()}
								sx={{
									display: 'block',
									marginBottom: 1,
									borderBottom: '1px solid black',
									padding: 1
								}}
							>
								<strong>{info.label}:</strong> {info.value}
							</Typography>
						);
					}

					return (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 1,
								marginBottom: 1,
								borderBottom: '1px solid black',
								padding: 1
							}}
							key={uuidv4()}
						>
							<Typography
								variant="body1"
								sx={{ display: 'block' }}
							>
								<strong>{info.label}:</strong>
							</Typography>
							{info.value}
						</Box>
					);
				})}
			</Box>
		</Grid>
	);
}

export default ClientSectionCard;
