import { Helmet } from 'react-helmet-async';
import { Routes, Route, Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// If this Route wraps a component it checks for user role.
import ProtectedRoute from 'components/ProtectedRouter/ProtectedRoute';

// Our Pages
import AdminMessagesPage from 'pages/Messages/AdminMessagesPage';
import AdvisorsPage from 'pages/AdvisorsPage';
import ClientsPage from 'pages/ClientsPage';
import ErrorPage from 'components/Error/ErrorPageNotFound.js';
import LoginPage from 'components/Login/LoginPage';
import LoanRequestDetailsPage from 'pages/NewLoan/LoanRequestDetailsPage';
import RoutingUser from 'pages/Login/RoutingUser';
import PostLoginRoutingUser from 'pages/Login/PostLoginRoutingUser';
import SettingsPage from 'pages/SettingsPage';
import TasksTabs from 'components/Tasks/TasksTabs';
import QueryEnginePage from 'pages/QueryEngine/QueryEnginePage';

// Our Frames
import ContainerFrameWithNav from 'components/Frame/ContainerFrameWithNav';

// Our Routes
import {
	ADVISORS_ROUTE,
	CLIENTS_ROUTE,
	LOAN_INQUIRY_DETAILS_ROUTE,
	SETTINGS_ROUTE,
	MESSAGES_ROUTE,
	QUERY_ENGINE_ROUTE
} from 'routes';

// CONSTANTS
const isRunningLocally = process.env.NODE_ENV === 'development'; // process.env.NODE_EV will be development if project is being run as local host.
const LATEST_GIT_VERSION = process.env.REACT_APP_GIT_VERSION;
const QUERY_CACHE_TIME = 1000 * 60 * 10; // 1000 ms * 60s * 10 => 10 minutes

// Changes default retry for both queries and mutations to 0 so a SINGLE request is made for now.
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0,
			cacheTime: QUERY_CACHE_TIME
		},
		mutations: {
			retry: 0,
			cacheTime: QUERY_CACHE_TIME
		}
	}
});

function App() {
	return (
		<div className="App">
			<Helmet>
				<title>Sora Admin Dashboard</title>
			</Helmet>
			<QueryClientProvider client={queryClient}>
				<Routes>
					<Route path="/" element={<RoutingUser />} />
					<Route path="/login" element={<LoginPage />} />
					<Route
						path="/post-login-routing-user"
						element={<PostLoginRoutingUser />}
					/>

					<Route path="*" element={<ErrorPage />} />

					<Route element={<ContainerFrameWithNav />}>
						<Route
							path={LOAN_INQUIRY_DETAILS_ROUTE}
							element={
								<ProtectedRoute
									element={<LoanRequestDetailsPage />}
								/>
							}
						/>
						<Route
							path="/dashboard"
							element={<ProtectedRoute element={<TasksTabs />} />}
						/>
						<Route
							path={MESSAGES_ROUTE}
							element={
								<ProtectedRoute
									element={<AdminMessagesPage />}
								/>
							}
						/>

						<Route
							path={ADVISORS_ROUTE}
							element={
								<ProtectedRoute element={<AdvisorsPage />} />
							}
						/>

						<Route
							path={CLIENTS_ROUTE}
							element={<ProtectedRoute element={<Outlet />} />}
						>
							<Route index element={<ClientsPage />} />
							<Route path=":clientId" element={<ClientsPage />} />
						</Route>

						<Route
							path={SETTINGS_ROUTE}
							element={
								<ProtectedRoute element={<SettingsPage />} />
							}
						/>
						<Route
							path={QUERY_ENGINE_ROUTE}
							element={
								<ProtectedRoute element={<QueryEnginePage />} />
							}
						/>
					</Route>

					{/* Outside of protected route, aka no frame no profile guarantee */}
				</Routes>

				{isRunningLocally && (
					<>
						<p
							style={{
								position: 'absolute',
								top: 2,
								right: 55,
								backgroundColor: 'white',
								padding: 2
							}}
						>
							Build Version:{' '}
							{LATEST_GIT_VERSION &&
								LATEST_GIT_VERSION.slice(0, 8)}
						</p>
						<ReactQueryDevtools initialIsOpen={false} />
					</>
				)}
			</QueryClientProvider>
		</div>
	);
}

export default App;
