import { useQuery } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { walletOverviewUrl } from 'shared/api-urls';

// Our Query Keys
import { WALLET_OVERVIEW_DATA } from 'shared/query-keys';

const FIVE_MINUTES = 1000 * 60 * 5;

function useGetWalletOverview(select, clientId) {
	return useQuery(
		[WALLET_OVERVIEW_DATA, clientId],
		async () => {
			const response = await axiosInstance.get(
				`${walletOverviewUrl}/${clientId}`
			);
			const clientWalletOverviewData = response.data.data;
			return clientWalletOverviewData;
		},
		{
			staleTime: FIVE_MINUTES,
			select: (walletOverviewData) => {
				if (select) return select(walletOverviewData);
				return walletOverviewData;
			},
			enabled: !!clientId
		}
	);
}

export default useGetWalletOverview;
