import { useState, useCallback, useMemo } from 'react';

// Our Components
import AdvisorAndClientSelectionDropdown from 'components/Dropdown/AdvisorAndClientSelectionDropdown';
import DynamicTabs from 'components/Tabs/DynamicTabs';
import PageHeading from 'components/PageHeading/PageHeading';
import QueryEngineAdvance from 'components/QueryEngine/QueryEngineAdvance';
import QueryEngineSimple from 'components/QueryEngine/QueryEngineSimple';

// Local Constants
const TABS_NAMES = [{ type: 'Advanced' }, { type: 'Simple' }];

function QueryEnginePage() {
	// Tab State
	const [tabPanelSelected, setTabPanelSelected] = useState(0);

	// Advisor and Client State
	const [selectedAdvisor, setSelectedAdvisor] = useState(null);
	const [selectedClients, setSelectedClients] = useState([]);

	const handleTabSelection = useCallback((e, value) => {
		setTabPanelSelected(value);
	}, []);

	const tabPanelItems = useMemo(() => {
		return [
			{
				children: (
					<QueryEngineAdvance
						selectedClients={selectedClients}
						selectedAdvisor={selectedAdvisor}
					/>
				)
			},
			{
				children: (
					<QueryEngineSimple
						selectedClients={selectedClients}
						selectedAdvisor={selectedAdvisor}
					/>
				)
			}
		];
	}, [selectedAdvisor, selectedClients]);

	const isEngineReady = selectedAdvisor;

	return (
		<>
			<PageHeading
				title="Query Engine"
				subtitleText="This tool allows you to query the database for specific client data"
			/>

			<AdvisorAndClientSelectionDropdown
				selectedAdvisor={selectedAdvisor}
				setSelectedAdvisor={setSelectedAdvisor}
				selectedClients={selectedClients}
				setSelectedClients={setSelectedClients}
			/>

			{isEngineReady && (
				<DynamicTabs
					tabItems={TABS_NAMES}
					tabItemSX={{
						padding: 0,
						textTransform: 'none',
						marginRight: 5
					}}
					tabsSX={{
						marginBottom: 4
					}}
					tabPanelItems={tabPanelItems}
					value={tabPanelSelected}
					handleChange={handleTabSelection}
					aria-label="Query Engine tabs"
				/>
			)}
		</>
	);
}

export default QueryEnginePage;
