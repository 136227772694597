import { useMutation, useQueryClient } from 'react-query';

// Our Axios Instances
import adminAxiosInstance from 'services/API/ADMINAPI';

// Our Endpoints
import { updateFreemiumCompanyEndpoint } from 'shared/api-urls';

// Our Query Keys
import { FREEMIUM_ADVISORS, FREEMIUM_COMPANIES } from 'shared/query-keys';

function useMutateToggleFreemium() {
	const queryClient = useQueryClient();
	return useMutation(
		async (companyId) => {
			const response = await adminAxiosInstance.patch(
				`${updateFreemiumCompanyEndpoint}/${companyId}`,
				{}
			);
			return response.data;
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries([FREEMIUM_ADVISORS]);
				await queryClient.invalidateQueries([FREEMIUM_COMPANIES]);
			}
		}
	);
}

export default useMutateToggleFreemium;
