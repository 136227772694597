import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';

// Our Components
import ClientSectionCard from 'components/SoraWallet/Cards/ClientSectionCard';
import ClientOnboardingState from 'components/SoraWallet/Cards/ClientOnboardingState';
import Modal from 'components/Modal/Modal';
import { SecondaryButton } from 'components/Button/Button';
import StateAlert from 'components/Alert/StateAlert';

// Our Utils
import { dollarFormatter } from 'shared/utils';

const getAppUrl = (clientId) => {
	const url = process.env.REACT_APP_LOGIN_URL;
	const urlWihoutLogin = url.replace('/login', '');

	return `${urlWihoutLogin}/sora-wallet-overview/advisor/${clientId}`;
};

const getStepLabel = (state) => {
	if (state === 'LIABILITY_ACCOUNT_CONNECTED')
		return 'Methodfi Account Connected';
	if (state === 'LIABILITIES_UPDATED') return 'Liabilities Updated';
	if (state === 'INCOME_UPDATED') return 'Income Updated';
	if (state === 'ASSETS_UPDATED') return 'Assets Updated';
	if (state === 'REVIEW_COMPLETED') return 'Review Completed';

	return 'Brand new client';
};

const CLIENT_ONBOARDING_STATES = new Set([
	'LIABILITY_ACCOUNT_CONNECTED',
	'LIABILITIES_UPDATED',
	'INCOME_UPDATED',
	'ASSETS_UPDATED',
	'REVIEW_COMPLETED'
]);

const getStepIndex = (onboardingState) => {
	const steps = Array.from(CLIENT_ONBOARDING_STATES);

	const removeDuplicateSteps = onboardingState.filter((state) =>
		CLIENT_ONBOARDING_STATES.has(state)
	);

	const orderOnboardingState = steps.map(
		(currentStep) =>
			removeDuplicateSteps.includes(currentStep) && currentStep
	);

	const reveresedOnboardingState = [...orderOnboardingState].reverse();

	const removeUnhappyValues = reveresedOnboardingState.filter(
		(state) => typeof state == 'string'
	);

	return steps.indexOf(removeUnhappyValues[0]);
};

function ClientSummaryCard({ clientWalletData }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isStateAlertOpen, setIsStateAlertOpen] = useState(false);

	const {
		advisor,
		address,
		annualIncome,
		assetsAmount,
		clientId,
		creditScore,
		dti,
		dts,
		firstName,
		lastName,
		email,
		lastRefreshDate,
		liability,
		totalUntappedSavings,
		totalUpsidePotential
	} = clientWalletData;

	const { addressLine1, city, state, zipcode } = address;

	const { onboardingState } = liability;

	const { company, email: advisorEmail, name } = advisor;

	const clientName = `${firstName} ${lastName}`;

	const currentClientOnboardingState =
		onboardingState[getStepIndex(onboardingState)];

	const formattedCurrentClientOnboardingState = getStepLabel(
		currentClientOnboardingState
	);

	const clientProfileInfo = [
		{
			label: 'Client Name',
			value: clientName
		},
		{
			label: 'Address',
			value: `${addressLine1}, ${city}, ${state} ${zipcode}`
		},
		{
			label: 'Email',
			value: email
		},
		{
			label: 'Last Refresh Date',
			value: lastRefreshDate ?? 'N/A'
		},
		{
			label: 'Onboarding State',
			value: (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						gap: 2
					}}
				>
					<Typography variant="body1">
						{formattedCurrentClientOnboardingState}
					</Typography>
					<SecondaryButton onClick={() => setIsModalOpen(true)}>
						View Onboarding State
					</SecondaryButton>
				</Box>
			)
		}
	];

	const clientFinancialInfo = [
		{
			label: 'Annual Income',
			value: dollarFormatter.format(annualIncome)
		},
		{
			label: 'Assets Amount',
			value: dollarFormatter.format(assetsAmount)
		},
		{
			label: 'Credit Score',
			value: creditScore
		},
		{
			label: 'DTI',
			value: dti
		},
		{
			label: 'DTS Status',
			value: dts?.dtsStatus
		},
		{
			label: 'DTS Score',
			value: dts?.dtsScore
		},
		{
			label: 'Total Untapped Savings',
			value: dollarFormatter.format(totalUntappedSavings)
		},
		{
			label: 'Total Upside Potential',
			value: dollarFormatter.format(totalUpsidePotential)
		}
	];

	const clientAdvisorInfo = [
		{
			label: 'Advisor Name',
			value: name
		},
		{
			label: 'Advisor Email',
			value: advisorEmail
		},
		{
			label: 'Advisor Company',
			value: company
		}
	];

	return (
		<>
			{isStateAlertOpen && (
				<StateAlert
					variant="success"
					isOpen={isStateAlertOpen}
					setIsOpen={setIsStateAlertOpen}
					message="Copied link to clipboard"
				/>
			)}
			<Grid container item xs={12} columnSpacing={2}>
				<ClientSectionCard
					clientData={clientProfileInfo}
					title="Client Profile Info"
				>
					<Box>
						<SecondaryButton
							onClick={() => {
								navigator.clipboard.writeText(
									getAppUrl(clientId)
								);
								setIsStateAlertOpen(true);
							}}
						>
							Copy link
						</SecondaryButton>
					</Box>
				</ClientSectionCard>
				<ClientSectionCard
					clientData={clientAdvisorInfo}
					title="Client Advisor Info"
				/>

				<ClientSectionCard
					clientData={clientFinancialInfo}
					title="Client Financial Info"
				/>
			</Grid>
			<Modal
				isOpen={isModalOpen}
				handleClose={() => setIsModalOpen(false)}
				title="Onboarding State"
			>
				<ClientOnboardingState onboardingState={onboardingState} />
			</Modal>
		</>
	);
}

export default ClientSummaryCard;
