import {
	Popover,
	FormControl,
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

function FilterPopover({
	children,
	headingLabel,
	filterBy,
	setFilterBy,
	popoverOpen,
	setPopoverOpen,
	anchorEl,
	filterOptions,
	onChange
}) {
	return (
		<Popover
			open={popoverOpen}
			anchorEl={anchorEl.current}
			onClose={() => setPopoverOpen(false)}
		>
			{children}
			{!children && (
				<FormControl sx={{ padding: 2 }}>
					<FormLabel>{headingLabel}</FormLabel>
					<RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						name="radio-buttons-group"
						onChange={(e) => {
							const selectedOption = e.target.value;

							// Default
							setFilterBy(selectedOption);
							setPopoverOpen(false);

							if (onChange) {
								onChange(selectedOption);
							}
						}}
						value={filterBy}
					>
						{filterOptions.map(({ value, label }) => (
							<FormControlLabel
								key={uuidv4()}
								value={value}
								control={<Radio />}
								label={label}
							/>
						))}
					</RadioGroup>
				</FormControl>
			)}
		</Popover>
	);
}

FilterPopover.defaultProps = {
	headingLabel: 'Filter by'
};

export default FilterPopover;
