import { useTheme } from '@emotion/react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import CommonPropTypes from 'shared/prop-types';

function SearchInput(props) {
	const SoraTheme = useTheme();

	const { black } = SoraTheme.palette.primary;

	return (
		<TextField
			{...props}
			variant="outlined"
			size="small"
			sx={{
				'& .MuiOutlinedInput-notchedOutline': {
					border: 'none'
				},
				width: '100%'
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon
							sx={{
								color: black
							}}
						/>
					</InputAdornment>
				),
				autoComplete: 'off',
				sx: {
					borderRadius: 20,
					background: '#E0E0E0',
					color: black,
					'& .MuiInputBase-input::placeholder': {
						color: black,
						opacity: 0.6
					}
				}
			}}
		/>
	);
}

SearchInput.propTypes = {
	sx: CommonPropTypes.sx,
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.bool,
	helperText: PropTypes.node,
	onChange: PropTypes.func,
	type: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SearchInput.defaultProps = {
	sx: {},
	id: undefined,
	name: undefined,
	label: undefined,
	error: false,
	helperText: undefined,
	onChange: () => {},
	type: 'text',
	defaultValue: undefined,
	value: undefined
};

export default SearchInput;
