import { useQuery } from 'react-query';

// our Axios instance
import adminAxiosInstance from 'services/API/ADMINAPI';

// endpoint
import { getAdvisorsOnboardedCountEndpoint } from 'shared/api-urls';

// query keys
import { ADVISORS_LIST } from 'shared/query-keys';

// our constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

// This one is used to get all the advisors list
// Should be used for tables to view general advisors list
function useGetAllAdvisorsList(select) {
	return useQuery(
		ADVISORS_LIST,
		async () => {
			const response = await adminAxiosInstance.get(
				getAdvisorsOnboardedCountEndpoint
			);

			const listOfAdvisors = response.data;

			const sortedAdvisorsByCreationDate = listOfAdvisors.sort(
				(a, b) => new Date(b.created) - new Date(a.created)
			);

			return sortedAdvisorsByCreationDate;
		},
		{
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES,
			select: (data) => {
				if (!select) return data;

				return select(data);
			}
		}
	);
}

export default useGetAllAdvisorsList;
