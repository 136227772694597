import { useState, useMemo } from 'react';
import {
	Autocomplete,
	Checkbox,
	Grid,
	Typography,
	TextField
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Our Components
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';

// Our Hooks
import useGetAllAdvisors from 'hooks/advisors/useGetAllAdvisors';
import useGetClients from 'hooks/clients/useGetClients';

function AdvisorAndClientSelectionDropdown({
	selectedAdvisor,
	setSelectedAdvisor,
	selectedClients,
	setSelectedClients
}) {
	// Advisor state
	const [advisorSearchName, setAdvisorSearchName] = useState('');

	// Client state
	const [clientSearchName, setClientSearchName] = useState('');

	// Filter Client List
	const [filterClientList, setFilterClientList] = useState('');

	const {
		data,
		isSuccess: isGetAdvisorsSuccess,
		isError: isGetAdvisorsError
	} = useGetAllAdvisors();

	// Hooks fetching Data
	const {
		data: clients,
		isLoading: isGetClientsLoading,
		isSuccess: isGetClientsSuccess,
		isError: isGetClientsError
	} = useGetClients(!!selectedAdvisor, selectedAdvisor?.id);

	// Creates a label set for each client. Sets these labels to an array of "items", which is used to populate the options within the client selection dropdown
	const listOfAdvisors = useMemo(() => {
		if (isGetAdvisorsSuccess) {
			const advisorList = data.map(({ fullName, advisorId }) => ({
				name: fullName,
				id: advisorId
			}));

			return advisorList;
		}

		return [];
	}, [data]);

	const listOfFilteredClients = useMemo(() => {
		if (isGetClientsSuccess) {
			const filteredClientList = clients.map(
				({ invitedName: fullName, clientId }) => {
					return {
						fullName,
						clientId
					};
				}
			);

			return filteredClientList;
		}

		return [];
	}, [selectedAdvisor, clients]);

	// Important Checks
	const isAdvisorSelected = !!selectedAdvisor;
	const advisorHasClients = listOfFilteredClients.length >= 1;

	const handleAdvisorSelect = (
		currentSelectedAdvisor,
		updateSelectedAdvisor
	) => {
		updateSelectedAdvisor(currentSelectedAdvisor);
		setSelectedAdvisor(currentSelectedAdvisor);
	};

	const handleAdvisorChange = (incomingValue) => {
		if (incomingValue === '') {
			setSelectedAdvisor('');
		}
		setAdvisorSearchName(incomingValue);
	};

	const handleClientSearchNameChange = (incomingValue) => {
		if (incomingValue === '') {
			setSelectedClients([]);
		}
		setClientSearchName(incomingValue);
	};

	return (
		<Grid
			container
			spacing={2}
			sx={{ alignItems: 'center', marginBottom: 1 }}
		>
			<Grid item xs={4}>
				{isGetAdvisorsSuccess && listOfAdvisors.length > 0 && (
					<AutocompleteTextinput
						forcePopupIcon
						freeSolo={false}
						getOptionLabel={({ name }) => name}
						handleChange={handleAdvisorChange}
						handleSelect={handleAdvisorSelect}
						label="Select an Advisor"
						helperText="Please select an advisor to query data for"
						value={advisorSearchName}
						optionList={listOfAdvisors}
						withoutFilter={false}
						renderOption={(props, option) => (
							<li {...props} key={option.id}>
								{option.name}
							</li>
						)}
					/>
				)}
			</Grid>
			{isGetAdvisorsError ||
				(isGetClientsError && (
					<Typography variant="subtitle1">
						Something went wrong...
					</Typography>
				))}

			{isGetClientsLoading && (
				<Grid item xs={4}>
					<LoadingSkeleton sx={{ height: 60, width: '100%' }} />
				</Grid>
			)}

			<Grid item xs={4}>
				{isAdvisorSelected && advisorHasClients && (
					<Autocomplete
						autoComplete
						autoHighlight
						disableCloseOnSelect
						getOptionLabel={({ fullName }) => fullName}
						multiple
						options={listOfFilteredClients}
						inputValue={clientSearchName}
						onInputChange={(e, newInputValue) =>
							handleClientSearchNameChange(newInputValue)
						}
						onChange={(e, incomingSelectedClient) => {
							const isRemovingAllClients =
								incomingSelectedClient.length < 1;

							if (isRemovingAllClients) {
								setFilterClientList('');
							}

							setSelectedClients(incomingSelectedClient);
						}}
						isOptionEqualToValue={(option, value) => {
							const { clientId } = option;

							const { clientId: selectedClientId } = value;

							return clientId === selectedClientId;
						}}
						renderInput={(params) => (
							<>
								<Typography
									variant="subtitle2"
									sx={{ marginBottom: 1 }}
								>
									Optionally select clients
								</Typography>
								<TextField
									{...params}
									type="text"
									variant="outlined"
									helperText="Selecting no clients will query all clients"
								/>
							</>
						)}
						renderOption={(props, option, { selected }) => (
							<li {...props} key={option.clientId}>
								<Checkbox
									icon={
										<CheckBoxOutlineBlankIcon fontSize="small" />
									}
									checkedIcon={
										<CheckBoxIcon fontSize="small" />
									}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option.fullName}
							</li>
						)}
						value={selectedClients}
					/>
				)}

				{!isGetClientsLoading &&
					isAdvisorSelected &&
					!advisorHasClients && (
						<Typography variant="subtitle1">
							No clients found for this advisor
						</Typography>
					)}
			</Grid>
		</Grid>
	);
}

export default AdvisorAndClientSelectionDropdown;
