import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Our components
import SearchInput from 'components/Input/SearchInput';

// Our Hooks
import useDebounce from 'hooks/useDebounce';

// Our Common Prop Types
import CommonPropTypes from 'shared/prop-types';

function SearchClients({ debounceCallBack, placeholder, sx }) {
	const [searchData, setSearchData] = useState('');

	const debouncedSearchResult = useDebounce(searchData );

	useEffect(() => {
		debounceCallBack(debouncedSearchResult);
	}, [debouncedSearchResult]);

	return (
		<SearchInput
			sx={sx ?? { width: '100%' }}
			autoComplete="off"
			value={searchData}
			onChange={(e) => setSearchData(e.target.value)}
			placeholder={placeholder}
		/>
	);
}

SearchClients.propTypes = {
	debounceCallBack: PropTypes.func,
	sx: CommonPropTypes.sx
};

SearchClients.defaultProps = {
	placeholder: 'Client Name'
};

export default SearchClients;
