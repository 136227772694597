import { useState } from 'react';
import { Box } from '@mui/material';

// our components
import TextInput from 'components/Input/TextInput';
import { TEXT } from 'components/Input/Types';
import Modal from 'components/Modal/Modal';
import { SecondaryButton } from 'components/Button/Button';

function FilterSubjectModal({
	isOpen,
	handleClose,
	resetInboxFeedFilter,
	inboxFeedFilter,
	setFeedFilter
}) {
	const [subject, setSubject] = useState('');

	const handleFilter = (e) => {
		e.preventDefault();
		setFeedFilter({ subject: ['==', subject] });
		setSubject('');
		handleClose(false);
	};

	return (
		<Modal
			title="Filter by subject"
			subtitle="Please note that this filter is case-sensitive"
			isOpen={isOpen}
			handleClose={() => {
				if (subject === '' || inboxFeedFilter.subject === undefined) {
					resetInboxFeedFilter();
				}

				setSubject('');
				handleClose(false);
			}}
		>
			<Box component="form" onSubmit={handleFilter}>
				<TextInput
					label="Enter a subject to filter by"
					value={subject}
					onChange={setSubject}
					type={TEXT}
					sx={{ marginBottom: 2 }}
				/>

				<SecondaryButton
					disabled={subject === ''}
					onClick={handleFilter}
					type="submit"
				>
					Filter
				</SecondaryButton>
			</Box>
		</Modal>
	);
}

export default FilterSubjectModal;
