import { useState } from 'react';

// Our Components
import ColumnItem from 'components/DataTable/ColumnItem';
import ColumnVerticalHeader from 'components/DataTable/ColumnVerticalHeader';
import DataTable from 'components/DataTable/DataTable';
import TradelineInfoModal from 'components/Modal/TradelineInfoModal';

// Our Utils
import { dollarFormatter, roundToDecimal } from 'shared/utils';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';

function QueryEngineTable({ queryResults }) {
	// Modal State
	const [isTradelineInfoModalOpen, setIsTradelineInfoModalOpen] =
		useState(false);

	// Query Result State
	const [selectedQueryResult, setSelectedQueryResult] = useState(null);

	// data Keys
	const tradelineType = 'tradelineType';
	const lender = 'lender';
	const clientName = 'clientName';
	const interestRate = 'interestRate';
	const outstandingBalance = 'outstandingBalance';
	const monthlyPayment = 'monthlyPayment';
	const creditScore = 'creditScore';

	const tableHeadings = [
		ColumnItem(tradelineType, 'Liability', 1.5, {
			valueGetter: (params) => {
				const currentTradelineType = params.row[tradelineType];

				if (currentTradelineType === 'CREDITCARD') return 'Credit Card';
				const formattedTradelineType = normalizeLoanType(
					params.row[tradelineType]
				);

				return formattedTradelineType;
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(lender, 'Lender', 2, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(clientName, 'Client Name', 2, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(interestRate, 'Interest Rate', 2, {
			valueFormatter: (params) => `${roundToDecimal(params.value)}%`,
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(outstandingBalance, 'Outstanding Balance', 2, {
			valueFormatter: (params) => dollarFormatter.format(params.value),
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(monthlyPayment, 'Monthly Payment', 2, {
			valueFormatter: (params) => dollarFormatter.format(params.value),

			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(creditScore, 'Credit Score', 2, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		})
	];

	const queryResultsWithId = queryResults.map((item) => ({
		...item,
		id: item.tradelineId
	}));

	return (
		<>
			<DataTable
				enableToolbar
				enableScrollToBottom
				columns={tableHeadings}
				height={700}
				rows={queryResultsWithId}
				handleRowClick={(liabilityClicked) => {
					if (liabilityClicked.tradelineType !== 'CREDITCARD') {
						setSelectedQueryResult(liabilityClicked);
						setIsTradelineInfoModalOpen(true);
					}
				}}
			/>
			{selectedQueryResult && (
				<TradelineInfoModal
					isOpen={isTradelineInfoModalOpen}
					handleClose={() => setIsTradelineInfoModalOpen(false)}
					queryResult={selectedQueryResult}
				/>
			)}
		</>
	);
}

export default QueryEngineTable;
