import { FormGroup, FormControlLabel, Switch } from '@mui/material';
import PropTypes from 'prop-types';
function BasicSwitch({ label, checked, onChange, labelPlacement }) {
	return (
		<FormGroup>
			<FormControlLabel
				value={labelPlacement}
				sx={{ textAlign: 'center' }}
				labelPlacement={labelPlacement}
				control={<Switch checked={checked} onChange={onChange} />}
				label={label}
			/>
		</FormGroup>
	);
}

BasicSwitch.propTypes = {
	label: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top'])
};

BasicSwitch.defaultProps = {
	checked: false,
	onChange: () => {},
	labelPlacement: 'top'
};

export default BasicSwitch;
