import { useState, useMemo } from 'react';
import { Typography } from '@mui/material';

// Our Components
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import Loader from 'components/Loader';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import Modal from 'components/Modal/Modal';
import { SecondaryButton, TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useGetAllAdvisors from 'hooks/advisors/useGetAllAdvisors';
import useGetClients from 'hooks/clients/useGetClients';

// TalkJs Hooks
import useMutateCreateGeneralChannel from 'hooks/talkjs/mutations/useMutateCreateGeneralChannel';
import useMutateCreateClientSpecificChannel from 'hooks/talkjs/mutations/useMutateCreateClientSpecificChannel';

function CreateChannelModal({ isOpen, handleClose, setCurrentConversation }) {
	// Advisor state
	const [selectedAdvisor, setSelectedAdvisor] = useState(null);
	const [advisorSearchName, setAdvisorSearchName] = useState('');

	// Client state
	const [selectedClient, setSelectedClient] = useState(null);
	const [clientSearchName, setClientSearchName] = useState('');

	const {
		data,
		isSuccess: isGetAdvisorsSuccess,
		isError: isGetAdvisorsError
	} = useGetAllAdvisors();

	// Hooks fetching Data
	const {
		data: clients,
		isLoading,
		isSuccess: isGetClientsSuccess,
		isError: isGetClientsError
	} = useGetClients(!!selectedAdvisor, selectedAdvisor?.id);

	// Mutations
	const createAdvisorGeneralChannel = useMutateCreateGeneralChannel();
	const createClientSpecificChannel = useMutateCreateClientSpecificChannel();

	// Creates a label set for each client. Sets these labels to an array of "items", which is used to populate the options within the client selection dropdown
	const listOfAdvisors = useMemo(() => {
		if (isGetAdvisorsSuccess) {
			const advisorList = data.map(({ fullName, advisorId }) => ({
				name: fullName,
				id: advisorId
			}));

			return advisorList;
		}

		return [];
	}, [data]);

	const listOfClients = useMemo(() => {
		if (isGetClientsSuccess) {
			return clients
				.filter((client) => client.clientId !== 0)
				.map(({ invitedName: clientFullName, clientId }) => ({
					name: clientFullName,
					id: clientId
				}));
		}
	}, [clients]);

	const handleAdvisorSelect = (
		currentSelectedAdvisor,
		updateSelectedAdvisor
	) => {
		updateSelectedAdvisor(currentSelectedAdvisor);
		setSelectedAdvisor(currentSelectedAdvisor);
	};

	const handleAdvisorChange = (incomingValue) => {
		if (incomingValue === '') {
			setSelectedAdvisor('');
		}
		setAdvisorSearchName(incomingValue);
	};

	const handleClientChange = (incomingValue) => {
		if (incomingValue === '') {
			setSelectedClient('');
		}
		setClientSearchName(incomingValue);
	};

	const handleClientSelect = (
		currentSelectedClient,
		updateSelectedClient
	) => {
		updateSelectedClient(currentSelectedClient);
		setSelectedClient(currentSelectedClient);
	};

	const isChannelCreationLoading =
		createAdvisorGeneralChannel.isLoading ||
		createClientSpecificChannel.isLoading;

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			title="Select an advisor to get started"
		>
			{isGetAdvisorsSuccess && listOfAdvisors.length > 0 && (
				<AutocompleteTextinput
					autoCompleteSX={{
						marginBottom: 4
					}}
					forcePopupIcon
					freeSolo={false}
					getOptionLabel={({ name }) => name}
					helperText="Select an advisor from the dropdown to create a general channel with them"
					handleChange={handleAdvisorChange}
					handleSelect={handleAdvisorSelect}
					label="Advisors"
					value={advisorSearchName}
					optionList={listOfAdvisors}
					withoutFilter={false}
					renderOption={(props, option) => (
						<li {...props} key={option.id}>
							{option.name}
						</li>
					)}
				/>
			)}

			{isLoading && (
				<LoadingSkeleton
					sx={{ height: 50, width: '100%', marginBottom: 8 }}
				/>
			)}

			{isGetAdvisorsError ||
				(isGetClientsError && (
					<Typography variant="subtitle1">
						Something went wrong...
					</Typography>
				))}

			{isGetClientsSuccess && listOfClients.length > 0 && (
				<AutocompleteTextinput
					autoCompleteSX={{
						marginBottom: 8
					}}
					forcePopupIcon
					freeSolo={false}
					getOptionLabel={({ name }) => name}
					helperText="Selecting a client will create a client specific channel"
					handleChange={handleClientChange}
					handleSelect={handleClientSelect}
					label="Optionally select a client"
					value={clientSearchName}
					optionList={listOfClients}
					withoutFilter={false}
					renderOption={(props, option) => (
						<li {...props} key={option.id}>
							{option.name}
						</li>
					)}
				/>
			)}

			{isChannelCreationLoading && <Loader />}

			{!isChannelCreationLoading && (
				<TertiaryButton
					isDisabled={selectedAdvisor === null}
					onClick={() => {
						const { id: advisorId } = selectedAdvisor;

						createAdvisorGeneralChannel.mutate(advisorId, {
							onSuccess: (conversationId) => {
								setCurrentConversation(conversationId);

								setSelectedAdvisor(null);
								setAdvisorSearchName('');
								handleClose();
							}
						});
					}}
					sx={{
						marginRight: selectedClient ? 4 : 0
					}}
				>
					Create advisor general channel
				</TertiaryButton>
			)}

			{selectedClient && !isChannelCreationLoading && (
				<SecondaryButton
					isDisabled={selectedClient === null}
					onClick={() => {
						const { id: advisorId } = selectedAdvisor;

						createClientSpecificChannel.mutate(
							{
								advisorId,
								clientInfo: selectedClient
							},
							{
								onSuccess: (conversationId) => {
									setCurrentConversation(conversationId);

									// Clean up advisor state
									setSelectedAdvisor(null);
									setAdvisorSearchName('');

									// Clean up client state
									setSelectedClient(null);
									setClientSearchName('');

									// Close the modal
									handleClose();
								}
							}
						);
					}}
				>
					Create client specific channel
				</SecondaryButton>
			)}
		</Modal>
	);
}

export default CreateChannelModal;
