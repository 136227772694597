import { useQuery } from 'react-query';

// our Axios instance
import axiosInstance from 'services/API/API';

// endpoint
import { getAdvisorsEndpoint } from 'shared/api-urls';

// query keys
import { LIST_OF_ADVISORS } from 'shared/query-keys';

// our utils
import { dataIsValid } from 'shared/utils';

// Constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useGetAllAdvisors(filterBy) {
	return useQuery(
		LIST_OF_ADVISORS,
		async () => {
			const response = await axiosInstance.get(getAdvisorsEndpoint);
			const listOfAdvisors = response.data.data;

			// default sort by fullName
			const sortedListOfAdvisors = listOfAdvisors.sort((a, b) => {
				return a.fullName.localeCompare(b.fullName);
			});

			return sortedListOfAdvisors;
		},
		{
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES,
			select: (data) => {
				const isFilterByValid = dataIsValid(filterBy);

				if (!isFilterByValid) return data;

				return data.sort((a, b) => {
					return a[filterBy].localeCompare(b[filterBy]);
				});
			}
		}
	);
}

export default useGetAllAdvisors;
