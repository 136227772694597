import { useMemo, useState } from 'react';
import { Grid, Typography, Switch } from '@mui/material';

// our components
import ColumnItem from 'components/DataTable/ColumnItem';
import ColumnVerticalHeader from 'components/DataTable/ColumnVerticalHeader';
import DataTable from 'components/DataTable/DataTable';
import FreemiumCheckBox from 'components/Checkbox/FreemiumCheckBox';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import SearchClients from 'components/Input/Clients/SearchClients';

// our hooks
import useGetAllFreemiumCompanies from 'hooks/freemium/useGetAllFreemiumCompanies';

function FreemiumTable() {
	const [paying, setPaying] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const {
		isLoading: isLoadingFreemiumCompanies,
		isSuccess: isSuccessFreemiumCompanies,
		data: freemiumCompanies
	} = useGetAllFreemiumCompanies(paying);

	const freemiumCompaniesList = useMemo(() => {
		if (freemiumCompanies) {
			return freemiumCompanies
				.map((company) => {
					return {
						...company,
						companyId: company.id
					};
				})
				.filter((company) => {
					const fieldsOfInterest = [company.name];

					return fieldsOfInterest.some((fieldValue) =>
						fieldValue
							.toLowerCase()
							.includes(searchTerm.toLowerCase())
					);
				});
		}
		return [];
	}, [freemiumCompanies, searchTerm]);

	const companyNameKey = 'name';
	const createdKey = 'created';

	const columnsCompanies = [
		ColumnItem(companyNameKey, 'Company', 1, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),

		ColumnItem(createdKey, 'Created', 1, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem('actions', 'Is Paying', 1, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			),
			renderCell: ({ row }) => <FreemiumCheckBox row={row} />
		})
	];

	if (isLoadingFreemiumCompanies) {
		return <LoadingSkeleton sx={{ width: '100%' }} />;
	}

	if (isSuccessFreemiumCompanies) {
		return (
			<>
				<Grid container item xs={12} sx={{ alignItems: 'center' }}>
					<Grid
						container
						item
						xs={12}
						md={3}
						xl={2}
						sx={{ alignItems: 'center' }}
					>
						<Typography variant="h6">
							{paying ? 'Paying Companies' : 'Free Companies'}
						</Typography>
						<Switch
							checked={paying}
							onChange={(e) => setPaying(e.target.checked)}
							color="primary"
						/>
					</Grid>
					<Grid
						container
						item
						xs={12}
						md={9}
						xl={10}
						sx={{ justifyContent: 'flex-end' }}
					>
						<Grid item xs={12} md={6} xl={3}>
							<SearchClients
								debounceCallBack={setSearchTerm}
								placeholder="Search Companies"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<DataTable
						columns={columnsCompanies}
						rows={freemiumCompaniesList}
						disableSelectionOnClick
						rowsPerPageOptions={10}
						handleRowClick={() => {}}
						withRowColorCheck
					/>
				</Grid>
			</>
		);
	}
}

export default FreemiumTable;
