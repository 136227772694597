import { useState, useMemo, useCallback } from 'react';
import { Typography } from '@mui/material';

// Components
import AdminInboxTab from 'components/Messaging/AdminInboxTab';
import DynamicTabs from 'components/Tabs/DynamicTabs';
import PageHeading from 'components/PageHeading/PageHeading';

// Local Constants
const TABS_NAMES = [{ type: 'Inbox' }];

function AdminMessagesPage() {
	// Tab State
	const [tabPanelSelected, setTabPanelSelected] = useState(0);

	const handleTabSelection = useCallback((e, value) => {
		setTabPanelSelected(value);
	}, []);

	const tabPanelItems = useMemo(() => {
		return [
			{
				children: <AdminInboxTab />
			}
		];
	}, []);

	return (
		<>
			<PageHeading
				title="Messages"
				subtitleText={
					<Typography variant="body2" sx={{ marginBottom: 2 }}>
						<br />
						You can use the General channel to send secure inquiries
						to Sora. <br />
						To request support for a particular client, select the
						client from the dropdown below and submit your inquiry.
						<br />
						Your clients do not see any messages submitted through
						the Message Center.
					</Typography>
				}
				sx={{
					marginBottom: 0
				}}
			/>

			<DynamicTabs
				tabItems={TABS_NAMES}
				tabItemSX={{
					padding: 0,
					textTransform: 'none',
					marginRight: 5
				}}
				tabsSX={{
					marginBottom: 2
				}}
				tabPanelItems={tabPanelItems}
				value={tabPanelSelected}
				handleChange={handleTabSelection}
				aria-label="Clients tabs"
				toolTipText="Coming soon..."
				variant="standard"
			/>
		</>
	);
}

export default AdminMessagesPage;
