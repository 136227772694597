import { useQuery } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { walletLiabilityURL } from 'shared/api-urls';

// Our Query Keys
import { TRADELINE_DATA } from 'shared/query-keys';

// Our Constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useGetWalletOverview(select, tradelineId) {
	const walletLiabilityEndpoint = `${walletLiabilityURL}/${tradelineId}`;

	return useQuery(
		[TRADELINE_DATA, tradelineId],
		async () => {
			const response = await axiosInstance.get(walletLiabilityEndpoint);
			const clientWalletOverviewData = response.data.data;
			return clientWalletOverviewData;
		},
		{
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES,
			select: (walletOverviewData) => {
				if (select) return select(walletOverviewData);
				return walletOverviewData;
			}
		}
	);
}

export default useGetWalletOverview;
