import { useMemo, useState } from 'react';
import { Grid } from '@mui/material';

// our components
import ColumnItem from 'components/DataTable/ColumnItem';
import ColumnVerticalHeader from 'components/DataTable/ColumnVerticalHeader';
import DataTable from 'components/DataTable/DataTable';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import SearchClients from 'components/Input/Clients/SearchClients';

// Our Hooks
import useGetAllAdvisorsList from 'hooks/advisors/useGetAllAdvisorsList';

function AllAdvisorsTable() {
	const [searchTerm, setSearchTerm] = useState('');

	const {
		isLoading: isLoadingAdvisorsList,
		isSuccess: isSuccessAdvisorsList,
		data: advisorsList
	} = useGetAllAdvisorsList();

	const advisorsListWithId = useMemo(() => {
		if (advisorsList) {
			return advisorsList
				.map((advisor, index) => {
					return {
						...advisor,
						id: index
					};
				})
				.filter((advisor) => {
					const fieldsOfInterest = [
						advisor.firstname,
						advisor.lastname,
						advisor.companyName,
						advisor.email,
						advisor.website
					];

					return fieldsOfInterest.some((fieldValue) =>
						fieldValue
							.toLowerCase()
							.includes(searchTerm.toLowerCase())
					);
				});
		}
		return [];
	}, [advisorsList, searchTerm]);

	const advisorFirstNameKey = 'firstname';
	const advisorLastNameKey = 'lastname';
	const advisorCompanyNameKey = 'companyName';
	const advisorEmailKey = 'email';
	const createdKey = 'created';
	const advisorWebsite = 'website';

	const columnsCompanies = [
		ColumnItem(advisorFirstNameKey, 'First Name', 0.6, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(advisorLastNameKey, 'Last Name', 0.6, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(advisorCompanyNameKey, 'Company', 0.6, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(advisorEmailKey, 'Email', 1, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(createdKey, 'Created', 0.6, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(advisorWebsite, 'Website', 1, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		})
	];

	if (isLoadingAdvisorsList) {
		return <LoadingSkeleton sx={{ width: '100%' }} />;
	}

	if (isSuccessAdvisorsList) {
		return (
			<>
				<Grid
					container
					item
					xs={12}
					sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
				>
					<Grid item xs={12} md={6} xl={3}>
						<SearchClients
							debounceCallBack={setSearchTerm}
							placeholder="Search Advisors"
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<DataTable
						columns={columnsCompanies}
						rows={advisorsListWithId}
						disableSelectionOnClick
						rowsPerPageOptions={10}
						handleRowClick={() => {}}
						withRowColorCheck
					/>
				</Grid>
			</>
		);
	}
}

export default AllAdvisorsTable;
