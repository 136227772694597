import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// Our Components
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import QueryEngineFilterChips from 'components/Chips/QueryEngineFilterChip';
import QueryEngineTable from 'components/DataTable/QueryEngineTable';
import TextInput from 'components/Input/TextInput';

// Our Hooks
import useMutateGenerateReport from 'hooks/genereateReport/useMutateGenerateReport';

// Our Constants
import {
	ALL,
	AUTO,
	MORTGAGE,
	STUDENT,
	PERSONAL,
	HELOC
} from 'shared/constants';

// Input Types
import { PERCENT } from 'components/Input/Types';

// Our Utils
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
import { isSubmissionReady } from 'shared/utils';
import { PrimaryButton } from 'components/Button/Button';

// Local Constants
const SUPPORTED_LOAN_TYPES = [
	ALL,
	AUTO,
	MORTGAGE,
	STUDENT,
	PERSONAL,
	HELOC
].map((loanType) => normalizeLoanType(loanType));

const SUPPORTED_QUERY_FIELDS = [
	'Interest Rate',
	'Outstanding Balance',
	'Monthly Payment'
];

const DEFAULT_DROPDOWN_LENGTH = SUPPORTED_QUERY_FIELDS.length;

const getFieldName = (currentFieldName) => {
	const separatedFieldName = currentFieldName.split(' ');
	const firstWord = separatedFieldName[0].toLowerCase();
	const restOfWords = separatedFieldName
		.slice(1)
		.map(
			(currentWord) => currentWord[0].toUpperCase() + currentWord.slice(1)
		);

	return firstWord + restOfWords.join('');
};

function QueryEngineAdvance({ selectedClients, selectedAdvisor }) {

	// Mutations
	const generateReportApiCall = useMutateGenerateReport();

	// Query Data
	const [liabilityCategories, setLiabilityCategories] = useState('');
	const [filterBy, setFilterBy] = useState('');
	const [comparisonQuery, setComparisonQuery] = useState('');
	const [betweenQueryUpperBound, setBetweenQueryUpperBound] = useState('');
	const [currentQueryValue, setCurrentQueryValue] = useState('');

	// Tag Data
	const [filterTags, setFilterTags] = useState([]);
	const [filterTagsAvailable, setFilterTagsAvailable] = useState(
		SUPPORTED_QUERY_FIELDS
	);

	const [queryResults, setQueryResults] = useState([]);

	const { isLoading: isQueryLoading, isSuccess: isQuerySuccess } =
		generateReportApiCall;

	const handleFilterByChange = (incomingValue) => {
		// If filter by changed reset the other options
		setComparisonQuery('');
		setCurrentQueryValue('');
		setBetweenQueryUpperBound('');

		// set filterBy on the new value;
		setFilterBy(incomingValue);
	};

	const clearInputFields = () => handleFilterByChange('');

	const isGenerateReportReady =
		liabilityCategories !== '' && filterTags.length > 0;

	const hasClients = selectedClients.length > 0;

	const isFabReady =
		isSubmissionReady([
			liabilityCategories,
			filterBy,
			comparisonQuery,
			currentQueryValue
		]) && filterTags.length < 4;

	const handleFabClick = () => {
		const currentQueryOption = {
			filterBy,
			comparisonQuery,
			currentQueryValue,
			betweenQueryUpperBound
		};

		setFilterTags(filterTags.concat(currentQueryOption));
		clearInputFields();
	};

	const handleGenerateReport = () => {
		const tradelineType = liabilityCategories.toUpperCase();

		const queryFieldtags = filterTags.map((currentFilterBy) => {
			const {
				betweenQueryUpperBound: currentUpperBound,
				filterBy: currentFieldName,
				comparisonQuery: currentComparisonQuery
			} = currentFilterBy;

			const fieldName = getFieldName(currentFieldName);

			if (currentComparisonQuery === 'Exactly') {
				return {
					lessThan: currentFilterBy.currentQueryValue,
					greaterThan: currentFilterBy.currentQueryValue,
					fieldName
				};
			}

			if (currentComparisonQuery === 'Greater than')
				return {
					greaterThan: +currentFilterBy.currentQueryValue,
					lessThan: Number.MAX_VALUE,
					fieldName
				};
			if (currentComparisonQuery === 'Less than')
				return {
					greaterThan: -Number.MAX_VALUE,
					lessThan: +currentFilterBy.currentQueryValue,
					fieldName
				};

			//
			return {
				fieldName,
				lessThan: +currentUpperBound,
				greaterThan: +currentFilterBy.currentQueryValue
			};
		});

		const reportQuery = {
			fields: queryFieldtags,
			tradelineType,
			advisorId: selectedAdvisor.id
		};

		// if the first item in clientId arr is an object with clientId of -1
		// it means the user selected ALL clients
		// and thus the BE spec is to omit `clients` from request payload

		if (hasClients) {
			reportQuery.clients = selectedClients.map(
				({ clientId }) => clientId
			);
		}

		generateReportApiCall.mutate(reportQuery, {
			onSuccess: ({ data: queryData }) => setQueryResults(queryData.data)
		});
	};

	const removeFilterTag = (filterTagClickedOn) => {
		setFilterTags(
			filterTags.filter(
				({ filterBy: currentFilterTag }) =>
					currentFilterTag !== filterTagClickedOn
			)
		);
	};

	function renderTextInputs(currentlySelectedFilter, comparison, isDisabled) {
		if (comparison === 'Between') {
			if (currentlySelectedFilter === 'Interest Rate') {
				return (
					<Box sx={{ display: 'flex', gap: 2 }}>
						<TextInput
							disabled={isDisabled}
							type={PERCENT}
							label="Lower Bound"
							value={currentQueryValue}
							onChange={setCurrentQueryValue}
						/>
						<TextInput
							disabled={isDisabled}
							type={PERCENT}
							label="Upper Bound"
							value={betweenQueryUpperBound}
							onChange={setBetweenQueryUpperBound}
						/>
					</Box>
				);
			}

			return (
				<Box sx={{ display: 'flex', gap: 2 }}>
					<CurrencyTextInput
						disabled={isDisabled}
						value={currentQueryValue}
						onChange={setCurrentQueryValue}
						label="Lower Bound"
					/>
					<CurrencyTextInput
						disabled={isDisabled}
						value={betweenQueryUpperBound}
						onChange={setBetweenQueryUpperBound}
						label="Upper Bound"
					/>
				</Box>
			);
		}

		if (currentlySelectedFilter === 'Interest Rate')
			return (
				<TextInput
					disabled={isDisabled}
					type={PERCENT}
					label="Value"
					value={currentQueryValue}
					onChange={setCurrentQueryValue}
				/>
			);
		return (
			<CurrencyTextInput
				disabled={isDisabled}
				value={currentQueryValue}
				onChange={setCurrentQueryValue}
				label="Value"
			/>
		);
	}

	// Changes the options for the dropdown
	useEffect(() => {
		const isFilterTagsEmpty = filterTags.length < 1;

		if (
			isFilterTagsEmpty ||
			(isFilterTagsEmpty &&
				filterTagsAvailable.length !== DEFAULT_DROPDOWN_LENGTH)
		) {
			setFilterTagsAvailable(SUPPORTED_QUERY_FIELDS);
		} else {
			const currentListOfTagsSelected = new Set(
				filterTags.map(
					({ filterBy: currentFilterTag }) => currentFilterTag
				)
			);
			const filteredListForDropdown = SUPPORTED_QUERY_FIELDS.filter(
				(currentFilterTag) =>
					!currentListOfTagsSelected.has(currentFilterTag) &&
					SUPPORTED_QUERY_FIELDS.includes(currentFilterTag)
			);

			setFilterTagsAvailable(filteredListForDropdown);
		}
	}, [filterTags]);

	// Checks
	const shouldDisableQueryAdditions = filterTags.length > 2;
	const hasCategoryBeenSelected = liabilityCategories !== '';
	const hasFilterTags = filterTags.length > 0;

	return (
		<>
			<Grid container spacing={2}>
				<Grid
					item
					xs={4}
					sx={{ marginBottom: hasCategoryBeenSelected ? 0 : 4 }}
				>
					<AutocompleteTextinput
						autoComplete
						freeSolo={false}
						helperText="select a tradeline category to filter results"
						optionList={SUPPORTED_LOAN_TYPES}
						label="Select a tradeline category:"
						handleChange={setLiabilityCategories}
						selected={liabilityCategories}
						withoutFilter={false}
					/>
				</Grid>

				<Grid item xs={8} />

				{hasCategoryBeenSelected && (
					<>
						<Grid item xs={12} md={4}>
							<Dropdown
								disabled={shouldDisableQueryAdditions}
								items={filterTagsAvailable}
								label="Filter"
								onChange={handleFilterByChange}
								selected={filterBy}
							/>
						</Grid>
						<Grid item xs={4}>
							<Dropdown
								disabled={shouldDisableQueryAdditions}
								items={[
									'Greater than',
									'Less than',
									'Exactly',
									'Between'
								]}
								label="Comparison"
								onChange={setComparisonQuery}
								selected={comparisonQuery}
							/>
						</Grid>

						<Grid item xs={4}>
							{renderTextInputs(
								filterBy,
								comparisonQuery,
								shouldDisableQueryAdditions
							)}
						</Grid>

						<Grid item xs={12} sx={{ marginBottom: 4 }}>
							<PrimaryButton
								isDisabled={!isFabReady}
								onClick={handleFabClick}
							>
								Add query filter
							</PrimaryButton>
						</Grid>
					</>
				)}

				{hasFilterTags && (
					<>
						<Grid
							container
							component={Paper}
							elevation={2}
							spacing={2}
							sx={{
								justifyContent: 'center',
								textAlign: 'center',
								minHeight: 150,
								borderRadius: 4,
								marginLeft: 2
							}}
						>
							<Grid item xs={5} sx={{ alignText: 'center' }}>
								<Typography
									variant="subtitle1"
									marginBottom={2}
								>
									Criteria (you can add up to 3):
								</Typography>
							</Grid>

							<Grid
								container
								item
								xs={12}
								sx={{ justifyContent: 'center', gap: 2 }}
							>
								<QueryEngineFilterChips
									filterTags={filterTags}
									filterTagsAvailable={filterTagsAvailable}
									removeFilterTag={removeFilterTag}
									setFilterTagsAvailable={
										setFilterTagsAvailable
									}
								/>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							{!isQueryLoading && (
								<PrimaryButton
									onClick={() => handleGenerateReport()}
									disabled={!isGenerateReportReady}
								>
									Query Results
								</PrimaryButton>
							)}
						</Grid>
					</>
				)}

				<Grid item xs={12}>
					{isQueryLoading && (
						<Skeleton
							variant="rectangular"
							width="100%"
							height={700}
						/>
					)}
					{isQuerySuccess && (
						<QueryEngineTable
							queryResults={queryResults}
							isLoading={isQueryLoading}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
}

export default QueryEngineAdvance;
