import { useQuery } from 'react-query';

// our axios instances
import adminAxiosInstance from 'services/API/ADMINAPI';

// our endpoints
import { getFreemiumAdvisorsEndpoint } from 'shared/api-urls';

// our query keys
import { FREEMIUM_ADVISORS } from 'shared/query-keys';

// Our Constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useGetAllFreemiumAdvisors(paying = false) {
	return useQuery(
		[FREEMIUM_ADVISORS, paying],
		async () => {
			const response = await adminAxiosInstance.get(
				getFreemiumAdvisorsEndpoint,
				{
					params: {
						paying
					}
				}
			);

			// Remove duplicates based on userId
			const uniqueAdvisors = Array.from(
				new Map(
					response.data.advisors.map((advisor) => [
						advisor.userId,
						advisor
					])
				).values()
			);

			const sortedAdvisorsByCreationDate = uniqueAdvisors.sort(
				(a, b) => new Date(b.created) - new Date(a.created)
			);

			return sortedAdvisorsByCreationDate;
		},
		{
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES
		}
	);
}

export default useGetAllFreemiumAdvisors;
