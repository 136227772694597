import { Checkbox } from '@mui/material';
import { useState } from 'react';

// Our Components
import Loader from 'components/Loader';

// Our Hooks
import useMutateToggleFreemium from 'hooks/freemium/useMutateToggleFreemium';

function FreemiumCheckBox({ row }) {
	const [checked, setChecked] = useState(row.paying);

	const { mutate: mutateToggleFreemium, isLoading: isLoadingToggleFreemium } =
		useMutateToggleFreemium();

	if (isLoadingToggleFreemium) {
		return <Loader />;
	}

	return (
		<Checkbox
			checked={checked}
			onChange={(e) => {
				e.stopPropagation();
				setChecked(!checked);
				mutateToggleFreemium(row.companyId);
			}}
		/>
	);
}

export default FreemiumCheckBox;
