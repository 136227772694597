import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@emotion/react';
import { format } from 'date-fns';

// Our Components
import ColumnItem from 'components/DataTable/ColumnItem';
import DataTable from 'components/DataTable/DataTable';
import TradelineInfoModal from 'components/Modal/TradelineInfoModal';
// Our Utils
import { dollarFormatter } from 'shared/utils';
import getLiabilityConfidence from 'shared/utils/calculations/getLiabilityConfidence';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
import sortLiabilitiesByKey from 'shared/utils/walletOverview/sortByKey';

// Our Constants
import { AUTO, CREDITCARD, HELOC, MORTGAGE, STUDENT } from 'shared/constants';

// Local Constants
const CONFIDENCE_ORDER = {
	High: 1,
	Medium: 2,
	Low: 3
};

// Helper Functions
const getFormattedUpdatedDate = (updatedDate) => {
	if (updatedDate) {
		return format(new Date(updatedDate), 'MM/dd/yyyy');
	}

	return 'NA';
};

const getLiabilityConfidenceColor = (confidenceLevel) => {
	const confidenceToColorMapping = {
		Low: 'red',
		Medium: '#bfa70a',
		High: 'green',
		manual: 'green',
		Manual: 'green'
	};

	return confidenceToColorMapping[confidenceLevel] ?? 'red';
};

const dataConfidenceSortComparator = (confidenceOne, confidenceTwo) => {
	const orderOne = CONFIDENCE_ORDER[confidenceOne] ?? 4; // Default to 4 if not found
	const orderTwo = CONFIDENCE_ORDER[confidenceTwo] ?? 4; // Default to 4 if not found

	return orderOne - orderTwo;
};

const initialSort = (totalLiabilitiesData) => {
	const liabilitySet = new Set([AUTO, CREDITCARD, HELOC, MORTGAGE, STUDENT]);

	const mortgageLiabilities = totalLiabilitiesData.filter(
		({ tradeLineType }) => tradeLineType === MORTGAGE
	);

	const helocLibailities = totalLiabilitiesData.filter(
		({ tradeLineType }) => tradeLineType === HELOC
	);

	const autoLiabilities = totalLiabilitiesData.filter(
		({ tradeLineType }) => tradeLineType === AUTO
	);

	const studentLoanLiabilities = totalLiabilitiesData.filter(
		({ tradeLineType }) => tradeLineType === STUDENT
	);

	const creditCardLiabilities = totalLiabilitiesData.filter(
		({ tradeLineType }) => tradeLineType === CREDITCARD
	);

	const otherLiabilities = totalLiabilitiesData.filter(
		({ tradeLineType }) => !liabilitySet.has(tradeLineType)
	);

	const sortedMortgages = sortLiabilitiesByKey(
		mortgageLiabilities,
		'outstandingBalance',
		true
	);

	const sortedHelocs = sortLiabilitiesByKey(
		helocLibailities,
		'outstandingBalance',
		true
	);

	const sortedAutoLoans = sortLiabilitiesByKey(
		autoLiabilities,
		'outstandingBalance',
		true
	);

	const sortedStudentLoans = sortLiabilitiesByKey(
		studentLoanLiabilities,
		'outstandingBalance',
		true
	);

	const sortedCreditCards = sortLiabilitiesByKey(
		creditCardLiabilities,
		'outstandingBalance',
		true
	);

	const sortedOtherLiabilities = sortLiabilitiesByKey(
		otherLiabilities,
		'outstandingBalance',
		true
	);

	return [
		...sortedMortgages,
		...sortedHelocs,
		...sortedAutoLoans,
		...sortedStudentLoans,
		...sortedCreditCards,
		...sortedOtherLiabilities
	];
};

function TotalLiabilitiesTable({
	bizLoans,
	clientData,
	totalLiabilities,
	liabilities
}) {
	const SoraTheme = useTheme();

	const formattedTotalLiabilities = dollarFormatter.format(totalLiabilities);

	// Modal State
	const [isTradelineInfoModalOpen, setIsTradelineInfoModalOpen] =
		useState(false);

	// Query Result State
	const [selectedQueryResult, setSelectedQueryResult] = useState(null);

	const typeKey = 'tradeLineType';
	const detailsKey = 'lender';
	const rateKey = 'interestRate';
	const balanceKey = 'outstandingBalance';
	const monthlyPayKey = 'monthlyPay';
	const dataConfidenceKey = 'liabilityConfidence';
	const lastUpdatedKey = 'lastUpdatedDate';

	const totalLiabilitiesData = useMemo(() => {
		const liabilitiesAndBizLoan = [...liabilities, ...bizLoans].map(
			(userLiabilityData) => ({
				...userLiabilityData,
				liabilityConfidence: getLiabilityConfidence(
					userLiabilityData.interestRateSource,
					userLiabilityData.rateManuallyUpdated
				),
				id: uuidv4()
			})
		);

		return initialSort(liabilitiesAndBizLoan);
	}, [liabilities, bizLoans]);

	const totalLiabilitiesColumns = [
		ColumnItem(typeKey, 'Type', 1, {
			valueFormatter: (params) => {
				const currentTradelineType = params.value;
				if (currentTradelineType === CREDITCARD) return 'Credit Card';

				const formattedType = normalizeLoanType(currentTradelineType);
				return formattedType;
			}
		}),
		ColumnItem(detailsKey, 'Details', 2),
		ColumnItem(rateKey, 'Rate', 0.6, {
			valueFormatter: (params) => `${params.value}%`
		}),
		ColumnItem(balanceKey, 'Balance', 1, {
			valueFormatter: (params) => dollarFormatter.format(params.value)
		}),
		ColumnItem(monthlyPayKey, 'Monthly Payment', 1, {
			valueFormatter: (params) => dollarFormatter.format(params.value)
		}),
		ColumnItem(dataConfidenceKey, 'Data Confidence', 1, {
			renderCell: ({ value }) => {
				const color = getLiabilityConfidenceColor(value);
				return (
					<Typography variant="body2" sx={{ color }}>
						{value}
					</Typography>
				);
			},
			sortComparator: dataConfidenceSortComparator
		}),
		ColumnItem(lastUpdatedKey, 'Last Updated', 1, {
			valueFormatter: (params) => getFormattedUpdatedDate(params.value)
		})
	];

	return (
		<>
			<Typography
				variant="h2Gascogne"
				sx={{
					color: SoraTheme.palette.primary.indigo,
					display: 'block',
					marginTop: 4
				}}
			>
				Total Liabilities: {formattedTotalLiabilities}
			</Typography>

			<Grid item xs={12}>
				<DataTable
					disableSelectionOnClick
					enableScrollToBottom
					columns={totalLiabilitiesColumns}
					rows={totalLiabilitiesData}
					rowsPerPageOptions={10}
					handleRowClick={(liabilityClicked) => {
						if (liabilityClicked.tradelineType !== 'CREDITCARD') {
							setSelectedQueryResult(liabilityClicked);
							setIsTradelineInfoModalOpen(true);
						}
					}}
					enableToolbar
				/>
			</Grid>

			{selectedQueryResult && (
				<TradelineInfoModal
					isOpen={isTradelineInfoModalOpen}
					handleClose={() => setIsTradelineInfoModalOpen(false)}
					queryResult={selectedQueryResult}
					clientData={clientData}
				/>
			)}
		</>
	);
}

TotalLiabilitiesTable.propTypes = {
	totalLiabilities: PropTypes.number.isRequired
};

export default TotalLiabilitiesTable;
