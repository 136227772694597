import { Step, StepLabel, Stepper } from '@mui/material';

const CLIENT_ONBOARDING_STATES = new Set([
	'LIABILITY_ACCOUNT_CONNECTED',
	'LIABILITIES_UPDATED',
	'INCOME_UPDATED',
	'ASSETS_UPDATED',
	'REVIEW_COMPLETED'
]);

const getStepIndex = (onboardingState) => {
	const steps = Array.from(CLIENT_ONBOARDING_STATES);

	const removeDuplicateSteps = onboardingState.filter((state) =>
		CLIENT_ONBOARDING_STATES.has(state)
	);

	const orderOnboardingState = steps.map(
		(currentStep) =>
			removeDuplicateSteps.includes(currentStep) && currentStep
	);

	const reveresedOnboardingState = [...orderOnboardingState].reverse();

	const removeUnhappyValues = reveresedOnboardingState.filter(
		(state) => typeof state == 'string'
	);

	return steps.indexOf(removeUnhappyValues[0]) + 1;
};

const getStepLabel = (state) => {
	if (state === 'LIABILITY_ACCOUNT_CONNECTED')
		return 'Methodfi Account Connected';
	if (state === 'LIABILITIES_UPDATED') return 'Liabilities Updated';
	if (state === 'INCOME_UPDATED') return 'Income Updated';
	if (state === 'ASSETS_UPDATED') return 'Assets Updated';
	if (state === 'REVIEW_COMPLETED') return 'Review Completed';
};

function ClientOnboardingState({ onboardingState }) {
	const steps = Array.from(CLIENT_ONBOARDING_STATES);

	return (
		<Stepper activeStep={getStepIndex(onboardingState)}>
			{steps.map((state) => (
				<Step key={state}>
					<StepLabel
						StepIconProps={{ sx: { fontSize: '1.2rem' } }}
						sx={{ display: 'flex', alignItems: 'center' }}
					>
						{getStepLabel(state)}
					</StepLabel>
				</Step>
			))}
		</Stepper>
	);
}

export default ClientOnboardingState;
