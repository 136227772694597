import { useQuery } from 'react-query';

// our axios instances
import adminAxiosInstance from 'services/API/ADMINAPI';

// our endpoints
import { getFreemiumCompaniesEndpoint } from 'shared/api-urls';

// our query keys
import { FREEMIUM_COMPANIES } from 'shared/query-keys';

// Our Constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useGetAllFreemiumCompanies(paying = false) {
	return useQuery(
		[FREEMIUM_COMPANIES, paying],
		async () => {
			const response = await adminAxiosInstance.get(
				getFreemiumCompaniesEndpoint,
				{
					params: {
						paying
					}
				}
			);

			const { companies } = response.data;

			const sortedCompaniesByCreationDate = companies.sort(
				(a, b) => new Date(b.created) - new Date(a.created)
			);

			return sortedCompaniesByCreationDate;
		},
		{
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES
		}
	);
}

export default useGetAllFreemiumCompanies;
